
import { Options, Vue } from 'vue-class-component';
import { AppUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class UniqueCodeLogin extends Vue {

    created() {
        var username = this.$route.params.username.toString();
        var uniqueCode = this.$route.params.uniqueCode.toString();

        var confirmVM = new OM.ConfirmVM();
        confirmVM.username = username;
        confirmVM.confirmationCode = uniqueCode;

        AppUserClient.confirm(confirmVM)
        .then(x => {
            LoginServices.LoginCallback(x);
        })
    }

}
